import React from 'react'
import './Program.css'
import {programsData} from '../../data/programsData'
import RightArrow from '../../assets/rightArrow.png'
const Program=()=>{
return(
    <div className='programs' id='programs'>
        {/* header */}
        <div className='programs-header'>
<span className='stroke-text'>برنامه‌ ریزی موفق </span>
<span>کمک می‌کند که در  </span>
<span className='stroke-text'>مسیر صحیح زندگی بمانید</span>
        </div>
        <div className='program-categories'>
{programsData.map((program)=>(
    <div className='category'>
        {program.image}
        <span>{program.heading}</span>
        <span>{program.details}</span>
        {/* <div className='join-now'> <img src={RightArrow} alt=''/><span>بزن بریم</span></div> */}
    </div>
))
}
        </div>
    </div>
)
}
export default Program;