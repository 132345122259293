import React, { useState } from 'react'
import './Header.css'
import Logo from '../../assets/logo.png'
import Logo1 from '../../assets/logo1.png'
import Bars from '../../assets/bars.png'
import { Link } from 'react-scroll'
const Header=()=>{
const mobile=window.innerWidth<=768 ?true :false;
const [menueOpened,setMenueOpened]=useState(false)

    return(
<div className='header'>
{/* <img src="{Logo}" alt='' className='logo' /> */}
<span></span>
{menueOpened===false && mobile===true ? (
    <div style={{backgroundColor:'var(--appColor)',padding:'0.5rem',borderRadius:'5px'}}
    onClick={()=>setMenueOpened(true)}
    >
        <img src={Bars} alt='' style={{width:'1.5rem' ,height:'1.5rem'}} />
    </div>
):(<ul className='header-menu'>
<li onClick={()=>setMenueOpened(false)}>
    <Link
     onClick={()=>setMenueOpened(false)}
     to='home'
     spy={true}
     smooth={true}
    >خانه</Link>
    </li>
<li onClick={()=>setMenueOpened(false)}>
<Link
    onClick={()=>setMenueOpened(false)}
    to='programs'
    spy={true}
    smooth={true}
    > برنامه ها</Link>
   </li>
<li onClick={()=>setMenueOpened(false)}>
    
<Link
    onClick={()=>setMenueOpened(false)}
    to='reasons'
    spy={true}
    smooth={true}
    >چرا ما</Link>
    </li>
<li onClick={()=>setMenueOpened(false)}>
<Link
    onClick={()=>setMenueOpened(false)}
    to='plan'
    spy={true}
    smooth={true}
    >تدابیر</Link>
    </li>
<li onClick={()=>setMenueOpened(false)}>
    <Link
    onClick={()=>setMenueOpened(false)}
    to='testimonials'
    spy={true}
    smooth={true}
    >توصیه ها</Link>
    </li>
</ul>)}


</div>
    )
}
export default Header;