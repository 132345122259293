import React from 'react'
import './Plans.css'
import {plansData} from '../../data/plansData'
import whiteTick from '../../assets/whiteTick.png'
const Plans=()=>{
return (
    <>
    <div className='plans-container'>
<div className='blur plans-blur-1'></div>
<div className='blur plans-blur-2'></div>
    <div className='programs-header' style={{gap:"10rem"}}>
            <span className='stroke-text'>دریافت خدمات اموزشی</span>
            <span>مشاوره و برنامه ریزی</span>
            <span className='stroke-text'>بر اساس بودجه و سلیقه خود</span>
        </div>
        {/* plans card */}
<div className='plans'>
{plansData.map((plan ,i)=>(
    <div className='plan' key={i}>
{plan.icon}
<span>{plan.name}</span>
<span></span>
<div className='features'>
    {plan.features.map((feature,i)=>(
        <div className='feature'>
            <img src={whiteTick} alt='' />
            <span key={i}>{feature}</span>
        </div>
    ))}
    </div>
<div>
    <span></span>
</div>
<button>برای اطلاعات بیشتر با ما تماس بگیرید </button>

    </div>
))}
</div>

    </div>
  
    </>
)
}
export default Plans;