import { useRef } from 'react';
import './Join.css'
import emailjs from '@emailjs/browser'
const Join=()=>{
    const form=useRef()
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_7pla91x', 'template_wwc7nig', form.current, 'bADbR4K0mf_J4JzA9')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };
    return (
        <div className='join' id='join-us'>
            <div className='right-j'>
                <hr/>
                <div>
                    <span className='stroke-text'>رویاهای شما</span>
                    <span>تاریخ انقضا ندارد</span>
                </div>
                <div>
                    <span>نفس عمیق بکش و دوباره شروع کن </span>
                    <span className='stroke-text'>با ما</span>
                </div>
            </div>
            <div className='left-j'>
                <form ref={form} action='' className='email-container' onSubmit={sendEmail}>
<input type='email' name='user_email' placeholder='Enter your name'/>
              <button className='btn btn-j'>بزن بریم</button>
                </form>
            </div>
        </div>
    )
}
export default Join;