import image1 from "../assets/t-image1.png";
import image2 from "../assets/t-image2.jpg";
import image3 from "../assets/t-image3.jpg";

export const testimonialsData = [
  {
    image: image1,
    review:
      "دارا بودن تحصیلات دانشگاهی کارشناسی مشاوره تحصیلی و 15 سال سابقه تدریس و مشاوره در تمام مقاطع دوره دوم دبیرستان ",
    name: 'امیر سلطانیه',
    status : 'کارشناس مشاوره'
  },
  {
    image: image2,
    review: 'کارشناس ارشد مشاوره تحصیلی و 12 سال سابقه تدریس و مشاوره تحصیلی در تمام پایه های دوره دوم دبیرستان ',
    name: 'حسین طالقانی',
    status: 'کارشناس ارشد مشاوره'
  },
  {
    image : image3,
    review:'داشتن تحصیلات دانشگاهی در مقطع کارشناسی مشاوره تحصیلی و 15 سال سابقه مشاوره در مقاطع سوم و پیش دانشگاهی',
    name: 'محسن عرشی',
    status: "کارشناس مشاوره"
  }
];
