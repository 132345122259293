import React from 'react';
import Header from '../Header/Header';
import './Hero.css';
import hero_image from '../../assets/hero_image.png';
import Img9 from '../../assets/img9.png'
import Heart from '../../assets/heart.png';
import Calories from '../../assets/calories.png'
import {motion} from 'framer-motion';
import NumberCounter from 'number-counter'
const Hero=()=>{
    const transition={type:'spring',duration:3}
    const mobile=window.innerWidth<=768 ? true :false;
    return(
        <div className="hero" id="home">
            <div className='blur hero-blur'></div>
        <div className='right-h'>
            <Header />
{/* the best ad */}
            <div className='the-best-ad'>
<motion.div
initial={{right:mobile ? "165px" :"238px"}}
whileInView={{right:'8px'}}
transition={{...transition,type:'tween'}}
>

</motion.div>
<span>بهترین مرکز مشاوره در ایران</span>

            </div>
         {/* hero heading    */}
         <div className='hero-text'>
<div>
    <span className='stroke-text'> هدف </span><span>ایده ال </span>
</div>

<div>
    <span>مسیر </span><span>درست</span>
</div>
<div>
    <span>
مرکز مشاوره تحصیلی (صبا) بامدیریت امیر سلطانیه در مقاطع مختلف تحصیلی ارایه کننده خدمات نوین ،به روز و علمی مشاوره تحصیلی و شغلی ،انتخاب رشته تحصیلی و دانشگاهی ،همایش  موفقیت تحصیلی و شغلی و همایش انتخاب رشته می باشد
        </span></div>
         </div>
         {/* figures */}
         <div className='figures'>
<div>
    <span>
        <NumberCounter end={10} start={1} delay='4' preFix='+' />
    </span>
    <span>تعداد مشاوران</span>
</div>
<div>
    <span>
    <NumberCounter end={93} start={0} delay='4' preFix='+' />
    </span>
    <span>تعداد کاربران</span>
</div>
<div>
    <span>
    <NumberCounter end={50} start={8} delay='4' preFix='+' />
    </span>
    <span>ازمونهای انلاین</span>
</div>
         </div>
         {/* hero buttons */}
         <div className='hero-buttons'>
<button className='btn'>از اینجا شروع کن</button>
<button className='btn'>بیشتر بدانید..</button>
         </div>
        </div>
        <div className='left-h'> 
        <button className='btn'>بزن بریم</button>
        
        <motion.div className='heart-rate'
        whileInView={{left:"4rem"}}
        transition={transition}
        initial={{left:'-1rem'}}
        >
<img src={Heart} alt=''/>
<span>آمار قبولی</span><span>+345</span>
        </motion.div>
        {/* hero image */}

        <img src={Img9} alt='' className='hero-image'/>
         {/* <motion.div 
           whileInView={{left:"32rem"}}
           transition={transition}
           initial={{left:'28rem'}}
         className='calories'>
           
            <div>
            <span>نمودار   </span>
            <span> نمرات</span>
            </div>
            <img src={Calories} alt=''/>
         </motion.div> */}
          </div>
        </div>
    )
}
export default Hero;