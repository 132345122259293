import React from 'react'
import image1 from '../../assets/image1.png'
import image2 from '../../assets/image2.png'
import image3 from '../../assets/image3.png'
import image4 from '../../assets/image4.png'
import nb from '../../assets/nb.png'
import adidas from '../../assets/adidas.png'
import nike from '../../assets/nike.png'
import tick from '../../assets/tick.png';
import './Reasons.css'
const Reasons=()=>{
    return(
        <div className='Reasons' id='reasons'>

            <div className='left-r'>
                <img src={image1} alt=''/>
                <img src={image2} alt='' />
                <img src={image3} alt=''/>
                <img src={image4} alt=''/>
            </div>
            <div className='right-r'>
                <span>بعضی دلایل</span>
                <div>
                    <span className='stroke-text'>چرا </span>
                    <span>انتخاب ما</span>
                </div>
<div className='details-r'>
    <div>
        <img src={tick} alt=''/>
        <span>مشاوره تحصیلی و برنامه ریزی توسط بهترین مشاوران کشور</span>
    </div>
    <div>
        <img src={tick} alt=''/>
        <span>ارسال برنامه درسی با توجه به وضعیت داوطلب</span>
    </div>
    <div>
        <img src={tick} alt=''/>
        <span>انتخاب رشته تضمینی دانشگاه آزاد و سراسری</span>
    </div>
    <div>
        <img src={tick} alt=''/>
        <span>تحلیل و آنالیز آزمونهای آزمایشی</span>
    </div>
    <div>
        <img src={tick} alt=''/>
        <span>نظارت بر عملکرد داوطلب</span>
    </div>
</div>
<span style={{color:'var(--gray)',fontWeight:'normal'}}>همراهان ما</span>
<div className='partners'>
    <img src={nb} alt='' />
    <img src={adidas} alt=''/>
    <img src={nike} alt='' />
</div>
            </div>
        </div>
    )
}
export default Reasons;